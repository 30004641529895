<template>
    <Card>
        <template #title>
            <h5 v-if='id === ""'>Nuovo record</h5>
            <h5 v-else>{{ pageTitle }}</h5>
        </template>
        <template #content v-if='row'>
            <PlainForm/>
        </template>
    </Card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Web/Offers');

import Factory from './model';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import Guards from '../../../mixins/guards';

import PlainForm from './Plain';

export default {
    beforeRouteLeave(to, from, next){
        this.beforeRouteLeaveCheck(to, from, next);
    },
    beforeRouteUpdate(to, from, next){
        this.beforeRouteUpdateCheck(to, from, next);
    },
    data(){
      return {};
    },
    mounted(){
        this.loadData(this.id);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'dirtyForm']),
    },
    methods: {
        ...mapActions(['fetchOne']),
        ...mapMutations(['setRow', 'formIsClean']),
        loadData(id){
            if (!id){
                this.setRow(Factory.newModel(this.languages));
            } else {
                this.fetchOne(id).then(res => {
                    this.setPageTitle(Factory.title(res));
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {});
            }

        }
    },
    props: {
        id: {
            default: 0,
            type: Number
        }
    },
    watch: {
        id(n, o){
            if (n !== o) {
                this.loadData(n);
            }
        }
    },
    mixins: [
        Notifications,
        Navigations,
        Guards
    ],
    components: {
        PlainForm
    }
}
</script>
