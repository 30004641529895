<template>
    <div class='grid p-fluid mt-6' v-if='value'>
        <div class='field col-12 md:col-6'>
            <div class='grid p-fluid formgrid'>
                <div class='field col-12'>
                    <span class='p-float-label'>
                        <InputText type='text' :id='"vimeo_src_"+num'
                                   v-model="value[iframe_src_attr]"
                        />
                        <label :for='"vimeo_src_"+num'>Iframe Src</label>
                    </span>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <div class='p-inputgroup'>
                        <span class='p-float-label'>
                            <InputText type='text' :id='"vimeo_style_"+num'
                                       v-model="value[iframe_style_attr]"
                            />
                            <Button label="Default" @click='value[iframe_style_attr] = modelDefault.iframe_style'/>
                            <label :for='"vimeo_style_"+num'>Iframe Style CSS</label>
                        </span>
                    </div>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <div class='p-inputgroup'>
                        <span class='p-float-label'>
                            <InputText type='text' :id='"vimeo_o_style_"+num'
                                       v-model="value[outer_style_attr]"
                            />
                            <Button label="Default" @click='value[outer_style_attr] = modelDefault.outer_style'/>
                        <label :for='"vimeo_o_style_"+num'>Outer Style CSS</label>
                    </span>
                    </div>
                </div>
            </div>
        </div>
        <div class='field col-12  md:col-6' v-if='value[iframe_src_attr] && value[iframe_src_attr]!==""'>
            <div :style='value[outer_style_attr]'>
                <iframe :src='value[iframe_src_attr]'
                        allow='autoplay; fullscreen; picture-in-picture'
                        :style='value[iframe_style_attr]'
                >
                </iframe>
            </div>
        </div>
    </div>
</template>
<script>

export default {

    data() {
        return {
            modelDefault: {
                iframe_style: 'position:absolute;top:0;left:0;width:100%;height:100%;border:0px',
                outer_style: 'padding:100% 0 0 0;position:relative;',
            },
        };
    },
    mounted(){
      let vimeoScript = document.createElement('script');
      vimeoScript.setAttribute('src', 'https://player.vimeo.com/api/player.js');
      document.head.appendChild(vimeoScript);
    },
    props: ['modelValue', 'outer_style_attr', 'iframe_style_attr', 'iframe_src_attr', 'num'],
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
};
</script>
