import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: 0,
    slug: "",
    lang: "it",
    active: false,
    plan_id: null,
    coupon: "",
    coupon_2: "",
    price: 0,
    discounted: 0,
    curr: "€",
    title: "",
    description: "",
    below_price: "",
    discount_line: "",
    btn_label: "",
    img: "",
    btn_below: "",
    tpl: "", //required
    seo_index: 0,
    plan_name: "",
    plan_name_2: "",
    plan_2_id: null,
    price_2: 0,
    discounted_2: 0,
    title_2: "",
    description_2: "",
    below_price_2: "",
    discount_line_2: "",
    btn_label_2: "",
    btn_below_2: "",
    logo: "",
    logo_mobile: "",
    checkout_type: "",
    external_attribute: "", //Only for lead magnet checkouts
    thank_you_title: "",
    thank_you_subtitle: "",
    thank_you_description: "",
    thank_you_image: "",
    thank_you_btn_text: "",
    thank_you_btn_custom_redirect: "",
    goto_thanx: false,
    vimeo_iframe_src: "",
    vimeo_iframe_style: "",
    vimeo_outer_style: "",
};

const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, ""),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            plan_id: {
                required
            },
            btn_label: {
                required
            },
            btn_below: {
                required
            },
            description: {
                required
            },
            title: {
                required
            },
            tpl: {
                required
            },
            external_attribute: {
                external_attribute: tools.models.validators.INTERCOM_ATTRIBUTE
            }
        }
    },
    translationValidation: {},
    uploads: [
        { el: "uppyImg", target: "img", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uppyLogo", target: "logo", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uppyLogoMobile", target: "logo_mobile", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uppyImgTu", target: "thank_you_image", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
    ]
};
