<template>
    <Dropdown
        v-model="value"
        :options="options"
        optionLabel="label"
        optionValue="value"
        :placeholder="placeholder" /></template>

<script>
export default {
    data(){
        return {
            options: [
                { label: "Default" , value: ""},
                { label: "Full Page" , value: "full_page"},
                { label: "Modal" , value: "in_app"},
            ]
        };
    },
    props: ['modelValue', 'placeholder'],
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>
